import {getUserDateFormat, getUserDateTimeFormat, getUserTimeFormat, getUserTimezone} from "@/composables/vuex";
import moment from "moment";
import momenttz from "moment-timezone";

// Use this for timestamps.
export function formatDate(dbDate: string | Date, format?: string): string {
  return dbDate
    ? moment(dbDate)
        .tz(getUserTimezone())
        .format(format || getUserDateFormat())
    : "";
}

// Use this for dates that should not have times, but might have legacy timezones.
export function formatDateAnswer(dbDate: string | Date, format?: string): string {
  if (!dbDate) {
    return "";
  }

  // Parse date as UTC, to avoid adjusting bare dates which moment() does.
  const m = moment.utc(dbDate);
  if (m.hours() >= 12) {
    // Must be next day.
    // For instance, Sydney Australia, UTC+10, midnight on Apr 13 is 2024-04-12T14:00:00.000Z in UTC.
    // Notice that is 14 hours but the day BEFORE.
    m.add(1, "day");
  }
  return m.format(format || getUserDateFormat());
}

export function formatTime(dbTime: string | number, format?: string): string {
  return dbTime
    ? moment(dbTime)
        .tz(getUserTimezone())
        .format(format || getUserTimeFormat())
    : "";
}

export function formatDateTimeWithZone(dbDate: string | Date): string {
  return momenttz(dbDate)
    .tz(getUserTimezone())
    .format(getUserDateTimeFormat() + ` z`);
}

export function formatDateTime(dbDate: string | Date, format?: string): string {
  return momenttz(dbDate)
    .tz(getUserTimezone())
    .format(format || getUserDateTimeFormat());
}

export function formatTime12HR(dbDate: string | Date): string {
  // formats with AM and PM, but only by default...?
  return momenttz(dbDate).tz(getUserTimezone()).format(getUserTimeFormat("h:mm A"));
}
