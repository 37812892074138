<template>
  <Badge :severity="severity" :class="customClass">
    <slot />
  </Badge>
</template>

<script lang="ts" setup>
import {defineProps, computed, toRefs} from "vue";
import Badge from "primevue/badge";

const props = defineProps({
  variant: {
    type: String,
    default: "secondary",
  },
});

const {variant} = toRefs(props);

const severity = computed(() => {
  let returnSeverity = undefined;
  switch (variant.value) {
    case "info":
      returnSeverity = "info";
      break;
    case "danger":
    case "warning":
    case "success":
      returnSeverity = variant.value;
      break;
  }
  return returnSeverity;
});

const customClass = computed(() => {
  if (!variant.value || variant.value === "secondary") {
    return {"p-badge-secondary": true};
  }

  return {};
});
</script>

<style lang="less" scoped>
@import "@{globals}";
</style>
