import {getCurrentProfile} from "@/composables/connections/get-current.profile";
import {
  getAnswerFromConnectionData,
  getConnectionIdFromRoute,
  getConnectionRoleFromConnectionData,
  getCurrentAnswerFromConnectionData,
} from "@/composables/connections/index";
import type {ApprovalElementCount, ConnectionProfileData} from "@/composables/connections/types";
import {breadcrumbDisplayTypes} from "@/composables/connections/types";
import {getStore} from "@/composables/get.store";
import {translate, getUserLocalizedText} from "@/composables/i18n";
import {FloatingChatWindowService} from "@/composables/messaging/floating-chat-window-service";
import {MessageRepo} from "@/composables/messaging/message-repo";
import {injectStrict} from "@/composables/provideInject";
import {
  GenerateElementActionsComputedKey,
  ShowElementActionsComputedKey,
  UseElementActionsKey,
} from "@/composables/questions/injections";
import {getRealId} from "@/composables/questions/internalGroup";
import type {
  ActionHandlers,
  AdditionalActionsData,
  AdditionalActionsDataAsRefs,
  ElementAction,
  OptionalElementDataArgs,
  QuestionInfoPaneData,
} from "@/composables/questions/types";
import {ActionStandardHandler, EditRules, ElementActionsShow} from "@/composables/questions/types";
import {getCurrentUser, getUserActiveEntityId} from "@/composables/vuex";
import {httpPost} from "@/composables/xhr";
import type {ComputedRef, Ref} from "vue";
import {computed} from "vue";
import type {AxiosResponse} from "axios";
import type {Task} from "pg-isomorphic/api/tasks";
import type {ConnectionRole} from "pg-isomorphic/enums";
import {Entity as Ent, GroupSubType, Profile} from "pg-isomorphic/enums";
import {MessageObjectType} from "pg-isomorphic/enums/message";
import {check, Permission} from "pg-isomorphic/permissions";
import {findParent, isReviewGroup} from "pg-isomorphic/profile";
import type {JSONQuestion} from "pg-isomorphic/utils";
import {any, find, path, pathOr} from "ramda";

export function approvalCountComputed(elementData: Ref<JSONQuestion>): ComputedRef<ApprovalElementCount> {
  return computed(() => elementData.value.approvalCount || {count: 0});
}

export function isActiveUsingId(id: string) {
  const store = getStore();
  const actionElemIds = [
    path(["message", "elementId"], store.state),
    path(["reminder", "elementId"], store.state),
    path(["notes", "elementId"], store.state),
    path(["history", "elementId"], store.state),
    path(["acknowledge", "acknowledgmentElementId"], store.state),
    path(["workflow", "workflowElementId"], store.state),
    path(["approvals", "approve", "elementId"], store.state),
  ];
  return actionElemIds.indexOf(id) !== -1;
}

export function isActiveInstanceUsingIds(id: string, instanceId: string) {
  const store = getStore();
  const actionElemIds = [
    `${path(["workflow", "workflowElementId"], store.state)}_${path(["workflow", "workflowInstanceId"], store.state)}`,
    `${path(["reminder", "elementId"], store.state)}_${path(["reminder", "instanceId"], store.state)}`,
    `${path(["notes", "elementId"], store.state)}_${path(["notes", "instanceId"], store.state)}`,
    `${path(["history", "elementId"], store.state)}_${path(["history", "instanceId"], store.state)}`,
    `${path(["message", "elementId"], store.state)}_${path(["message", "instanceId"], store.state)}`,
    `${path(["approvals", "approve", "elementId"], store.state)}_${path(
      ["approvals", "approve", "instanceId"],
      store.state,
    )}`,
  ];
  return actionElemIds.indexOf(`${id}_${instanceId}`) !== -1;
}

export function getBreadcrumbs(parentElement?: JSONQuestion): string[] {
  const breadcrumbs: string[] = [];
  let element: JSONQuestion | undefined = parentElement;
  while (element && breadcrumbs.length < 2) {
    if (element.type in breadcrumbDisplayTypes && element.label) {
      breadcrumbs.unshift(element.label);
    }
    element = element.parent;
  }
  return breadcrumbs;
}

export function initReminder(
  elementData: JSONQuestion,
  entityLogo: {fileId: string},
  entityName: string,
  entityId: string,
  instanceId?: string,
): void {
  const store = getStore();
  const connectionId = getConnectionIdFromRoute();
  const contextImg = entityLogo && entityLogo.fileId ? `/api/files/${entityLogo.fileId}?thumbnail=true` : "";
  const breadcrumbs = getBreadcrumbs(elementData);
  const elementId = elementData.instance ? elementData.parent._id : elementData._id;

  store.dispatch("reminder/initReminder", {
    breadcrumbs,
    reminderTitle: elementData.label,
    connectionId,
    elementId,
    instanceId,
    entityId,
    contextImg,
    contextName: entityName,
  });
}
export function initReminderAlt(id, label, breadcrumbs, instanceId) {
  const store = getStore();
  const connectionId = getConnectionIdFromRoute();

  store.dispatch("reminder/initReminder", {
    breadcrumbs,
    reminderTitle: label,
    connectionId,
    elementId: id,
    instanceId,
    entityId: getUserActiveEntityId(),
  });
}

export function initNotes(elementData: JSONQuestion, entityId: string, theirEntityId: string, instanceId?: string) {
  const elementId = elementData.instance ? elementData.parent._id : elementData._id;
  const connectionId = getConnectionIdFromRoute();
  getStore().dispatch("notes/initNotes", {
    connectionId,
    elementId,
    label: elementData.label,
    instanceId: instanceId || undefined,
    entityId,
    theirEntityId,
  });
}

export function initApproval(
  elementId: string,
  approvalId: string,
  taskId: string,
  question: JSONQuestion,
  logoId: string,
  entityName: string,
  instanceId?: string,
) {
  const contextImg = logoId ? `/api/files/${logoId}?thumbnail=true` : "";
  getStore().dispatch("approvals/approve/init", {
    elementId,
    approvalId,
    taskId,
    question,
    contextImg,
    contextName: getUserLocalizedText(entityName),
    instanceId,
  });
}

export function initWorkflow(
  workflowElementId: string,
  workflowTaskId: string,
  tasks?: Task[],
  isGlobalCommunicationEmail?: boolean,
) {
  getStore().dispatch("workflow/init", {workflowElementId, workflowTaskId, tasks, isGlobalCommunicationEmail});
}

export function initWorkflowQuestionPane(
  workflowElementId: string,
  workflowTaskId: string,
  tasks?: Task[],
): QuestionInfoPaneData {
  const task = tasks?.find((t) => t._id === workflowTaskId);
  return {
    elementId: workflowElementId,
    data: task,
  };
}

export function initManageApprovals({
  question,
  pertainingToEntityId,
  connectionId,
  instanceId,
  approvalId,
  approvals,
  actionPlanId,
  placeAboveMenu,
  connectionRole,
}: {
  question?: JSONQuestion;
  pertainingToEntityId: string;
  connectionId?: string;
  instanceId?: string;
  approvalId?: string;
  approvals?: any;
  actionPlanId?: string;
  placeAboveMenu?: boolean;
  connectionRole?: ConnectionRole;
}) {
  getStore().dispatch("approvals/manage/init", {
    question,
    pertainingToEntityId,
    connectionId,
    instanceId,
    approvalId,
    approvals,
    actionPlanId,
    connectionRole,
    placeAboveMenu,
  });
}

export function initExportTable(elementData: JSONQuestion, entityId: string, connectionId?: string) {
  const downloadParams = {
    download: true,
    limit: 0,
    page: 0,
    entityIds: [entityId],
    connectionId,
    groupId: elementData._id,
  };
  getStore().dispatch("export/start", {params: downloadParams, title: elementData.tableTitle}, {root: true});
}

/**
 * For any element that implements element actions (using ellipsis) they will have these common values
 *
 * @param elementData
 * @param optionalElementData
 */
export function setupElementActionSupportForSingleElement(
  elementData: Ref<JSONQuestion>,
  optionalElementData?: AdditionalActionsDataAsRefs,
) {
  const showElementActionsInject = injectStrict(ShowElementActionsComputedKey, () => ElementActionsShow.DO_NOT_SHOW);
  const elementActionsInject = injectStrict(GenerateElementActionsComputedKey, () => []);
  const useElementActions = injectStrict(UseElementActionsKey, false);

  const showElementActions = computed(() => showElementActionsInject(elementData.value));
  const elementActions = computed(() =>
    elementActionsInject(elementData.value, {
      hasOwnQuestions: optionalElementData?.hasOwnQuestions?.value,
      comprisedOfReadOnly: optionalElementData?.comprisedOfReadOnly?.value,
      inEditMode: optionalElementData?.inEditMode?.value,
      instanceId: optionalElementData?.instanceId?.value,
      entityId: optionalElementData?.entityId?.value,
      theirEntityId: optionalElementData?.theirEntityId?.value,
      tasks: optionalElementData?.tasks?.value,
      connectionId: optionalElementData?.connectionId?.value,
      editRules: optionalElementData?.editRules?.value,
      is2faUnlocked: optionalElementData?.is2faUnlocked?.value,
    }),
  );

  return {showElementActions, elementActions, useElementActions};
}

export function messageIcon(elementData: JSONQuestion) {
  return pathOr(0, ["messageCount", "count"], elementData) ? ["fas", "comment-alt"] : ["fal", "comment-alt"];
}

export function notesIcon(elementData: JSONQuestion) {
  return pathOr(0, ["noteCount", "count"], elementData) ? ["fas", "sticky-note"] : ["fal", "sticky-note"];
}

export function taskIcon(elementData: JSONQuestion) {
  return pathOr(0, ["taskCount", "count"], elementData)
    ? ["fas", "clipboard-list-check"]
    : ["fal", "clipboard-list-check"];
}

export function reminderIcon(elementData: JSONQuestion) {
  return pathOr(0, ["reminderCount", "count"], elementData) ? ["fas", "clock"] : ["fal", "clock"];
}

export function approvalIcon(elementData: JSONQuestion) {
  return pathOr(0, ["approvalCount", "count"], elementData) ? ["fas", "clipboard-check"] : ["fal", "clipboard-check"];
}

export function findInstance(elementData: JSONQuestion) {
  if (elementData.instance) {
    return elementData.instance;
  }
  let current = elementData;
  let instanceQuestion;
  while (current.parent && !instanceQuestion) {
    const parent = current.parent;
    instanceQuestion = parent?.instance;
    current = parent;
  }
  if (!instanceQuestion) {
    return null;
  }
  return instanceQuestion;
}

export function getQuestionActions(
  elementData: JSONQuestion,
  connectionData: ConnectionProfileData,
  params: AdditionalActionsData,
  handlers?: ActionHandlers,
) {
  let actions: ElementAction[] = [];
  const user = getCurrentUser();
  // This is the same logic that dblclick uses.
  const showEdit =
    (params.editRules === EditRules.CAN_ONLY_EDIT_STANDARD_QUESTIONS ||
      params.editRules === EditRules.CAN_EDIT_INTERNAL_USE_AND_STANDARD ||
      !!elementData.internalUse ||
      elementData.counterpartyCanEditAnswer) &&
    (!elementData.readOnly || (elementData.secured && params.is2faUnlocked));

  if (
    showEdit &&
    check(Permission.SIDE_RAIL_EDIT, user) &&
    check(Permission.WRITE_ANSWERS, user) &&
    !params.isOnReferenceDrawer
  ) {
    actions.push({
      icon: ["fal", "pencil-alt"],
      clickHandler: () => null,
      standardClickHandler: ActionStandardHandler.EDIT,
      text: translate("questions.edit"),
    });
  }
  if (check(Permission.CREATE_MANUAL_TASKS, user) && connectionData.connection?._id) {
    actions.push({
      icon: taskIcon(elementData),
      clickHandler: () => handlers.tasks(),
      text: translate("questions.tasks"),
      class: "not-on-mobile",
    });
  }
  if (check(Permission.SIDE_RAIL_HISTORY, user) && !elementData.isCalculation) {
    actions.push({
      icon: ["fal", "history"],
      clickHandler: () => handlers.history(),
      text: translate("questions.history"),
      class: "not-on-mobile",
    });
  }
  if (check(Permission.CREATE_NOTES, user)) {
    actions.push({
      icon: notesIcon(elementData),
      clickHandler: () => initNotes(elementData, params.entityId, params.theirEntityId, params.instanceId),
      text: translate("questions.notes"),
      class: "not-on-mobile",
    });
  }
  if (check(Permission.CREATE_REMINDERS, user)) {
    actions.push({
      icon: reminderIcon(elementData),
      clickHandler: () =>
        initReminder(elementData, {fileId: params.logoId}, params.entityName, params.entityId, params.instanceId),
      text: translate("questions.reminder"),
      showDot: Boolean(pathOr(0, ["overdueCount"], elementData.reminderCount) > 0), // this.unreadReminder,
      class: "not-on-mobile",
    });
  }
  if (check(Permission.CREATE_MESSAGES, user) && !params.isOnConnectionOverviewTab) {
    actions.push({
      icon: messageIcon(elementData),
      clickHandler: async () => {
        const threadEntityId = params?.entityId;
        FloatingChatWindowService.open(
          await MessageRepo.initThreadByMeta({
            threadEntityId,
            connectionId: getConnectionIdFromRoute() || undefined,
            elementId: getRealId(elementData._id),
            instanceId: findInstance(elementData),
            objectType: MessageObjectType.ELEMENT,

            fallbackEntityName: params.entityName,
            fallbackEntityLogo: params.logoId && `/api/files/${params.logoId}?thumbnail=true`,
            fallbackElementLabel: elementData.label,
            fallbackIsInternal: elementData.internalUse,
            fallbackCounterpartyId: params.theirEntityId,
          }),
        );
      },
      text: translate("questions.messages"),
      showDot: Boolean(pathOr(0, ["unreadCount"], elementData.messageCount) > 0),
      class: "not-on-mobile",
    });
  }
  if (check(Permission.MANAGE_BUSINESS_APPROVALS, user)) {
    actions.push({
      icon: approvalIcon(elementData),
      clickHandler: () =>
        initManageApprovals({
          question: elementData,
          instanceId: params.instanceId,
          connectionId: connectionData.connection?._id,
          pertainingToEntityId: connectionData.current.entity.id,
          connectionRole: getConnectionRoleFromConnectionData(getUserActiveEntityId(), connectionData),
        }),
      text: translate("questions.approvals"),
      class: "not-on-mobile",
    });
  }
  return actions;
}

export function getTopicActions(
  elementData: JSONQuestion,
  connectionData: ConnectionProfileData,
  optionalElementData?: OptionalElementDataArgs,
  handlers?: ActionHandlers,
) {
  let actions: ElementAction[] = [];
  const user = getCurrentUser();
  const currentProfile = getCurrentProfile();
  const entityId = getAnswerFromConnectionData(connectionData, currentProfile, "entityId");
  const theirEntityId = getAnswerFromConnectionData(connectionData, Profile.THEIRS, "entityId");
  const instanceId = elementData.instance ? elementData.instance : undefined;
  const logoId = getCurrentAnswerFromConnectionData(connectionData, Ent.Logo);
  const entityName = getCurrentAnswerFromConnectionData(connectionData, Ent.Name);

  const hasItemGroupChild = Boolean(
    find((c: JSONQuestion) => c.subType === GroupSubType.ITEM && c.visible, elementData.children),
  );
  const showHistory = optionalElementData.hasOwnQuestions || hasItemGroupChild;
  const showApprovals = !(
    findParent(isReviewGroup)(elementData) ||
    any((q: JSONQuestion) => q.subType === GroupSubType.REVIEW, elementData.children)
  );

  // This is the same logic as Topic.vue
  const showEdit =
    optionalElementData.hasOwnQuestions &&
    !optionalElementData.comprisedOfReadOnly &&
    (optionalElementData.editRules === EditRules.CAN_ONLY_EDIT_STANDARD_QUESTIONS ||
      optionalElementData.editRules === EditRules.CAN_EDIT_INTERNAL_USE_AND_STANDARD ||
      elementData.internalUse) &&
    check(Permission.WRITE_ANSWERS, user) &&
    elementData.editable;
  if (showEdit && check(Permission.SIDE_RAIL_EDIT, user) && check(Permission.WRITE_ANSWERS, user)) {
    actions.push({
      icon: ["fal", "pencil-alt"],
      standardClickHandler: ActionStandardHandler.EDIT,
      text: translate("questions.edit"),
    });
  }
  if (check(Permission.CREATE_MANUAL_TASKS, user) && optionalElementData.connectionId) {
    actions.push({
      icon: taskIcon(elementData),
      clickHandler: () => handlers.tasks(),
      text: translate("questions.tasks"),
      class: "not-on-mobile",
    });
  }
  if (showHistory && check(Permission.SIDE_RAIL_HISTORY, user)) {
    actions.push({
      icon: ["fal", "history"],
      clickHandler: () => handlers.history(),
      text: translate("questions.history"),
      class: "not-on-mobile",
    });
  }
  if (check(Permission.CREATE_NOTES, user)) {
    actions.push({
      icon: notesIcon(elementData),
      clickHandler: () => initNotes(elementData, entityId, theirEntityId, instanceId),
      text: translate("questions.notes"),
      class: "not-on-mobile",
    });
  }
  if (check(Permission.CREATE_REMINDERS, user)) {
    actions.push({
      icon: reminderIcon(elementData),
      clickHandler: () => initReminder(elementData, {fileId: logoId}, entityName, entityId, instanceId),
      text: translate("questions.reminder"),
      showDot: Boolean(pathOr(0, ["overdueCount"], elementData.reminderCount) > 0), // this.unreadReminder,
      class: "not-on-mobile",
    });
  }
  if (check(Permission.CREATE_MESSAGES, user)) {
    actions.push({
      icon: messageIcon(elementData),
      text: translate("questions.messages"),
      showDot: Boolean(pathOr(0, ["unreadCount"], elementData.messageCount) > 0),
      clickHandler: async () => {
        FloatingChatWindowService.open(
          await MessageRepo.initThreadByMeta({
            threadEntityId: entityId,
            connectionId: getConnectionIdFromRoute() || undefined,
            elementId: elementData.instance ? elementData.parent._id : elementData._id,
            instanceId,
            objectType: MessageObjectType.ELEMENT,

            fallbackEntityName: connectionData.current.entity.name,
            fallbackEntityLogo: connectionData.current.entity.logo,
            fallbackElementLabel: elementData.label,
            fallbackIsInternal: elementData.internalUse,
            fallbackCounterpartyId: connectionData.theirs?.entity.id,
          }),
        );
      },
      class: "not-on-mobile",
    });
  }
  if (showApprovals && check(Permission.MANAGE_BUSINESS_APPROVALS, user)) {
    actions.push({
      icon: approvalIcon(elementData),
      clickHandler: () =>
        initManageApprovals({
          question: elementData.instance ? elementData.parent : elementData,
          instanceId,
          connectionId: connectionData.connection?._id,
          pertainingToEntityId: connectionData.current.entity.id,
          connectionRole: getConnectionRoleFromConnectionData(getUserActiveEntityId(), connectionData),
        }),
      text: translate("questions.approvals"),
      class: "not-on-mobile",
    });
  }
  return actions;
}

export function getTableHeaderActions(
  elementData: JSONQuestion,
  connectionData: ConnectionProfileData,
  optionalElementData?: OptionalElementDataArgs,
  handlers?: ActionHandlers,
) {
  let actions: ElementAction[] = [];
  const user = getCurrentUser();
  const entityId = getCurrentAnswerFromConnectionData(connectionData, "entityId");

  if (check(Permission.SIDE_RAIL_HISTORY, user)) {
    actions.push({
      icon: ["fal", "history"],
      clickHandler: () => handlers.history(),
      text: translate("questions.history"),
      class: "not-on-mobile",
    });
  }
  if (
    check(Permission.SIDE_RAIL_EXPORT, user) &&
    optionalElementData.editRules === EditRules.CAN_ONLY_EDIT_INTERNAL_USE &&
    user.userCanExport
  ) {
    actions.push({
      icon: ["fal", "file-export"],
      clickHandler: () => {
        initExportTable(elementData, entityId, optionalElementData.connectionId);
      },
      text: translate("actions.export"),
      class: "export-action not-on-mobile",
    });
  }
  if (
    check(Permission.CREATE_MANUAL_TASKS, user) &&
    connectionData.connection?._id &&
    pathOr(0, ["taskCount", "count"], elementData)
  ) {
    actions.push({
      icon: taskIcon(elementData),
      clickHandler: () => handlers.tasks(),
      text: translate("questions.tasks"),
      class: "not-on-mobile",
    });
  }

  return actions;
}

export function getTableRowActions(
  elementData: JSONQuestion,
  instanceData: JSONQuestion,
  instanceId: string,
  connectionData: ConnectionProfileData,
  optionalElementData?: OptionalElementDataArgs,
  handlers?: ActionHandlers,
) {
  let actions: ElementAction[] = [];
  const user = getCurrentUser();
  const entityId = getCurrentAnswerFromConnectionData(connectionData, "entityId");
  const theirEntityId = getAnswerFromConnectionData(connectionData, Profile.THEIRS, "entityId");
  const logoId = getCurrentAnswerFromConnectionData(connectionData, Ent.Logo);
  const entityName = getCurrentAnswerFromConnectionData(connectionData, Ent.Name);

  if (check(Permission.SIDE_RAIL_HISTORY, user)) {
    actions.push({
      icon: ["fal", "history"],
      clickHandler: () => handlers.history(),
      text: translate("questions.history"),
    });
  }
  if (check(Permission.CREATE_NOTES, user)) {
    actions.push({
      icon: notesIcon(instanceData),
      clickHandler: () => {
        initNotes(elementData, entityId, theirEntityId, instanceId);
      },
      text: translate("questions.notes"),
    });
  }
  if (check(Permission.CREATE_MANUAL_TASKS, user) && connectionData.connection?._id) {
    actions.push({
      icon: taskIcon(instanceData),
      clickHandler: () => handlers.tasks(),
      text: translate("questions.tasks"),
    });
  }
  if (check(Permission.CREATE_REMINDERS, user)) {
    actions.push({
      icon: reminderIcon(instanceData),
      clickHandler: () => initReminder(elementData, {fileId: logoId}, entityName, entityId, instanceId),
      text: translate("questions.reminder"),
      showDot: Boolean(pathOr(0, ["overdueCount"], elementData.reminderCount) > 0), // this.unreadReminder,
    });
  }
  if (check(Permission.CREATE_MESSAGES, user)) {
    actions.push({
      icon: messageIcon(instanceData),
      clickHandler: async () => {
        FloatingChatWindowService.open(
          await MessageRepo.initThreadByMeta({
            threadEntityId: entityId,
            connectionId: getConnectionIdFromRoute() || undefined,
            elementId: elementData.instance ? elementData.parent._id : elementData._id,
            instanceId,
            objectType: MessageObjectType.ELEMENT,

            fallbackEntityName: connectionData.current.entity.name,
            fallbackEntityLogo: connectionData.current.entity.logo,
            fallbackElementLabel: elementData.label,
            fallbackIsInternal: elementData.internalUse,
            fallbackCounterpartyId: connectionData.theirs?.entity.id,
          }),
        );
      },
      text: translate("questions.messages"),
      showDot: Boolean(pathOr(0, ["unreadCount"], elementData.messageCount) > 0),
    });
  }
  const allowAddApprovals =
    check(Permission.OVERRIDE_RESTRICT_MANUAL_APPROVAL, user) || elementData.restrictManualApproval !== true;
  if (check(Permission.MANAGE_BUSINESS_APPROVALS, user) && allowAddApprovals) {
    actions.push({
      icon: approvalIcon(instanceData),
      clickHandler: () => {
        initManageApprovals({
          question: elementData,
          instanceId,
          connectionId: connectionData.connection?._id,
          pertainingToEntityId: connectionData.current.entity.id,
          connectionRole: getConnectionRoleFromConnectionData(getUserActiveEntityId(), connectionData),
        });
      },
      text: translate("questions.approvals"),
    });
  }
  return actions;
}

export async function getRecentChanges(
  acknowledgmentElementId: string,
  connectionId: string,
  pertainingToEntityId: string,
) {
  const recentlyUpdatedParams = {
    connectionId,
    acknowledgmentElementId,
    pertainingToEntityId,
  };
  // TODO - make recent changes api contract
  const response: AxiosResponse<{results: {[answerKey: string]: any}}> = await httpPost(
    "/api/answers/recent_changes",
    recentlyUpdatedParams,
  );
  return response.data.results;
}
