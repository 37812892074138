import {invert} from "ramda";

export {State, Impact} from "./actionplan";
export {
  Address,
  Banking,
  Connection,
  Contracts,
  CustomESFields,
  Directory,
  Entity,
  FreeTextAnswerKeys,
  GST,
  Tax,
  Tin,
  TopicReviewAnswerKeys,
  Internal,
  RiskLevels,
  Subsidiaries,
  ThumbDirection,
} from "./answers";
export {
  ConnectionCancelAction,
  ConnectionRole,
  Stage,
  getOppositeConnectionRole,
  ConnectionParty,
  ConnectionSearchStatus,
  ConnectionSearchScope,
  oppositeProfile,
  Profile,
  stageNumber,
} from "./connections";
export {ApprovalObjectType} from "./approval";
export {AccessType, ElementType, GroupType, GroupSubType, QuestionType, SecurityRole, TableColumnType} from "./element";
export {SsoType} from "./entity";
export {NoteType} from "./note";
export {Channel} from "./notification";
export {Format, RiskAssessment, SkipRuleTarget, QuestionTag} from "./question";
export {EntityAccessStatus, RoleType, RoleTypeName, SpecialUser} from "./users";
export {
  TaskType,
  TaskChangeType,
  TaskActorType,
  TaskChangeReason,
  TaskOnHoldReasons,
  TaskState,
  TaskSpecialActor,
  TaskGroupBy,
} from "./task";
export {ErrorCode} from "./errors";
export {ReminderType} from "./reminder";
export {SearchScope, SearchScopeCategory} from "./search";
export {SystemValueSet} from "./valuesets";
export {ValidationStatus} from "./validation";

export enum Locale {
  EN_US = "en-US",
  ES_ES = "es-ES",
  RU_RU = "ru-RU",
  ZH_CN = "zh-CN",
  ZH_TW = "zh-TW",
  FR_FR = "fr-FR",
  JA_JP = "ja-JP",
  VI_VN = "vi-VN",
  DE_DE = "de-DE",
  HE_IL = "he-IL",
  KO_KR = "ko-KR",
  PT_BR = "pt-BR",
  HU_HU = "hu-HU",
  IT_IT = "it-IT",
  PL_PL = "pl-PL",
  HI_IN = "hi-IN",
  NL_NL = "nl-NL",
  TH_TH = "th-TH",
}

export function getSupportedLocales(): Locale[] {
  return Object.values(Locale);
}

export function isValidEnum<T>(s: string | undefined, enumObj: any): T | undefined {
  if (!s) {
    return undefined;
  }
  const lookup = invert(enumObj);
  if (lookup[s]) {
    return s as unknown as T;
  }
  return undefined;
}
