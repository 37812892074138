import {onUnmounted} from "vue";
import {RegisteredErrors} from "@/composables/errorHandling/registeredErrors";
import type {ErrorDetails, ErrorHandler} from "@/composables/errorHandling/types";
import {pathOr} from "ramda";

/**
 * A simple class to register error handles. If an error happens that does not have a registered handler it will default to the
 * global handler.
 */
class ErrorHandlerRouter {
  /**
   * Tracks error handling registration
   *
   * CONSIDER: we might want to allow more than one registration for an error (switch to array)
   */
  static registeredHandlers = {} as {[handlerKey in RegisteredErrors]: ErrorHandler};

  /**
   * Registers an error handler for an error type (error type must be defined in enum)
   * @param handlerKey
   * @param handler
   */
  static registerHandler(handlerKey: RegisteredErrors, handler: ErrorHandler) {
    ErrorHandlerRouter.registeredHandlers[handlerKey] = handler;
  }

  /**
   * Unregisters an error handler
   * @param handlerKey
   */
  static unregisterHandler(handlerKey: RegisteredErrors) {
    delete ErrorHandlerRouter.registeredHandlers[handlerKey];
  }

  /**
   * Use this method to pass in error. For example in a catch statement.
   * @param handlerKey
   * @param errorDescription
   * @param errorDetails
   */
  static routerError(handlerKey: RegisteredErrors, errorDescription: string, errorDetails: ErrorDetails) {
    const userHandlerKey = ErrorHandlerRouter.registeredHandlers[handlerKey] ? handlerKey : RegisteredErrors.GLOBAL;
    if (ErrorHandlerRouter.registeredHandlers[userHandlerKey]) {
      ErrorHandlerRouter.registeredHandlers[userHandlerKey](errorDescription, errorDetails);
    }
  }
}

/**
 * A shortcut method to use the ErrorHandlerRouter
 *
 * @param handlerKey
 * @param errorDescription
 * @param errorDetails
 */
export function handleError(handlerKey: RegisteredErrors, errorDescription: string, errorDetails: ErrorDetails) {
  ErrorHandlerRouter.routerError(handlerKey, errorDescription, errorDetails);
}

/**
 * Shortcut method to use the ErrorHandlerRouter, but specifically for XHR request catches
 *
 * @param handlerKey
 * @param errorDescription
 * @param httpError
 */
export function handleXhrError(handlerKey: RegisteredErrors, errorDescription: string, httpError: any) {
  ErrorHandlerRouter.routerError(handlerKey, errorDescription, {
    httpStatus: httpError.response?.status,
    error: pathOr("", ["response", "data", "code"], httpError),
    fullRequest: httpError,
  });
}

/**
 * This contains all the code to setup a handler for an error within a composable component. This will setup the handler and unset
 * it when the component is unmounted.
 *
 * While I usually don't like lifecycle stuff hidden in methods, in this case I think it's nice doing everything in a single
 * method.
 *
 * @param handlerKey
 * @param handler
 */
export function setupComponentErrorHandler(handlerKey: RegisteredErrors | RegisteredErrors[], handler: ErrorHandler) {
  const keys: RegisteredErrors[] = !Array.isArray(handlerKey) ? [handlerKey] : handlerKey;

  for (const key in keys) {
    ErrorHandlerRouter.registerHandler(keys[key], handler);

    onUnmounted(() => {
      ErrorHandlerRouter.unregisterHandler(keys[key]);
    });
  }
}
