import type {PGActionTree} from "@/store/index-types";
import {autoMutations} from "@/store/utils";
import cloneDeep from "lodash/cloneDeep";
import type {LoaderHistoryApiResponse, LoaderHistoryItem} from "pg-isomorphic/api/loader";
import {LoaderResults} from "pg-isomorphic/api/loader";
import {LoaderStatus} from "pg-isomorphic/enums/loader";
import {RoutingKey} from "pg-isomorphic/queue";

export interface LoaderState {
  status: LoaderStatus | null;
  results: Partial<LoaderResults>;
  complete: boolean;
  inProgress: boolean;
  jobId: string | null;
  history: LoaderHistoryItem[];
  historyLoading: boolean;
}

const initialState: LoaderState = {
  status: null,
  results: {errors: []},
  complete: false,
  inProgress: false,
  jobId: null,
  history: [],
  historyLoading: false,
};

let jobMessage: (x: any) => void = () => {
  /**/
};

const actions: PGActionTree<LoaderState> = {
  joinRooms({state, commit}) {
    jobMessage = (wsMessage) => {
      if (wsMessage.status) {
        commit("status", wsMessage.status);
        if (wsMessage.status === LoaderStatus.LOADING_FAILED || wsMessage.status === LoaderStatus.LOADING_COMPLETE) {
          commit("complete", true);
          commit("inProgress", false);
        }
      }
      if (wsMessage.results) {
        commit("results", wsMessage.results);
      }
    };
    this.join(RoutingKey.jobProgress({jobId: state.jobId!}));
    this.socketOn(RoutingKey.jobProgress({jobId: state.jobId!}), jobMessage);
  },
  leaveRooms({state, commit}) {
    commit("status", null);
    commit("results", {errors: []});
    commit("jobId", null);

    this.leave(RoutingKey.jobProgress({jobId: state.jobId!}));
    this.socketOff(RoutingKey.jobProgress({jobId: state.jobId!}), jobMessage);
  },
  startUpload({commit}) {
    commit("status", LoaderStatus.UPLOADING);
    commit("results", new LoaderResults());
    commit("complete", false);
    commit("inProgress", true);
  },
  async refreshHistory({state, commit}, filter) {
    commit("historyLoading", true);
    const response = await this.httpGet<LoaderHistoryApiResponse>(
      `/api/admin/tools/questions/history?${new URLSearchParams({filter})}`,
    );
    commit("history", response.data.history);
    commit("historyLoading", false);
  },
  async loadMoreHistoryResults({state, commit}, filter) {
    commit("historyLoading", true);
    const response = await this.httpGet<LoaderHistoryApiResponse>(
      `/api/admin/tools/questions/history?${new URLSearchParams({filter, offset: String(state.history.length)})}`,
    );
    const completeList = state.history.concat(response.data.history);
    commit("history", completeList);
    commit("historyLoading", false);
  },
};

export default {
  namespaced: true,
  state: cloneDeep(initialState),
  mutations: autoMutations(initialState),
  actions,
};
